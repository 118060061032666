import axios from "axios";
import qs from 'qs'
import headerConfig from './api_header_config'
import {del, get, post, put} from "@/service/base";
import {postFeedBackById} from "@/service/base_y";

const header = headerConfig.api_header
export function getCase(url,memberId, params) {
    return axios.get(header + url, {
        headers:{'memberId':memberId},
        params,
    }).then((res) => {
        return res.data
    }).catch((e) => {
        console.log(e)
    })
}

//获取标签
export function  getCase_tags(data){
    return get('/v3/systemLabel',data)
}

export function  putCase_tags(id,content,status){
    return put('/v3/systemLabel?id='+id+'&content='+content+'&status='+status)
}

export function  postCase_tags(content,status){
    return postFeedBackById('/v3/systemLabel?content='+content+'&status='+status)
}
export function  delCase_tags(id){
    return del('/v3/systemLabel?id='+id)
}


//使用明细
export function  getCase_detail(data){
    return get('/v3/patientManage',data)
}
export function  getCase_patient(data){
    return get('/v3/casePatient',data)
}
export function  getTags(id){
    return getCase('/case/caseLabel',id)
}

//病程导出分析
//折线图
export function  getCaseExportList(data){
    return get('/v3/caseExportList',data)
}
export function caseExportNum(){
    return get('/v3/caseExportNum')
}
export function caseExportUserNum(){
    return get('/v3/caseExportUserNum')
}
export function caseExportWordNum(){
    return get('/v3/caseExportWordNum')
}
export function caseExportExcelNum(){
    return get('/v3/caseExportExcelNum')
}

//标签使用分析
export function labelManageNum(){ //
    return get('/v3/labelManageNum')
}
export function labelQueryNum(){ //
    return get('/v3/labelQueryNum')
}
export function caseTagNum(){ //新建标签
    return get('/v3/caseLabelNum')
}
export function caseSystemTagNum(){ //系统标签使用次数
    return get('/v3/systemLabelUserNum')
}
export function caseTagNumTop(data){ //标签使用t10
    return get('/v3/caseLabelTop',data)
}
export function caseLabelPercent(type){ //标签使用饼状图
    return get('/v3/caseLabelPercent?type='+type)
}
export function caseLineQueryList(data){ //标签使用折线图
    return get('/v3/labelQueryList',data)
}

//功能使用
export function caseFolderNum(){ //病例夹
    return get('/v3/caseFolderNum')
}
export function caseBinNum(){ //病例夹-回收站
    return get('/v3/binNum')
}
export function casePatientNum(){ //病例夹-回收站
    return get('/v3/patientNum')
}
export function caseDetailNum(){ //病例夹-回收站
    return get('/v3/detailNum')
}
export function caseFunNum(data){
    return get('/v3/listByType',data)
}